import * as _ from 'lodash';
import $ from 'jquery';

import LazyLoader from '../model/lazy-loading';
import Mutex from '../model/mutex';
import TableauViz from './tableau-viz';

function _createVizsLoader(vizs) {
  return new LazyLoader(vizs, viz => viz.load(), {
    isReady: viz => viz.isVisible
  });
}

function _destructVizs(vizs, loader) {
  for (let viz of vizs) {
    viz.dispose();
  }

  loader.clear().then(() => {
    for (let viz of vizs) {
      viz.dispose();
    }
  });
}

async function _preferInViewVizs(loader, tableauId) {
  await loader.preferBy(['index']);
  await loader.preferAfter(viz => viz.tableauId === tableauId);
}

export class SingleGraphView {
  constructor(contentQuery, options = {}) {
    this._contentQuery = contentQuery;
    this._concurrency = options.concurrency || 2;

    this.$content = $(this._contentQuery);
    this._vizs = [];
    this._loader = _createVizsLoader(this._vizs);

    this._setActions();
    this._load();
  }

  _setActions() {
    const that = this;

    this.$content.find('.viz-container').on('inview', (event, inView) => {
      if (!inView) {
        return false;
      }
      const tableauId = event.currentTarget.dataset.tableauId;
      _preferInViewVizs(that._loader, tableauId);
      that.findVizs(tableauId).forEach(viz => viz.updateSize());
    });
  }

  async _load() {
    const vizContainers = this.$content.find('.viz-container');
    const vizs = vizContainers
      .map((index, elem) => new TableauViz(elem, { index }))
      .get();

    const loader = _createVizsLoader(vizs);
    for (let i = 0; i < this._concurrency; ++i) {
      loader.run();
    }

    this._vizs = vizs;
    this._loader = loader;
  }

  findVizs(tableauId) {
    return _.filter(this._vizs, viz => viz.tableauId == tableauId);
  }
}

export class ArchiveGraphView {
  constructor(contentQuery, options = {}) {
    this._contentQuery = contentQuery;

    this._controlQuery = options.controlQuery || '.graph-control';
    this._baseControlQuery = options.baseControlQuery || '.graph-control-base';
    this._parentContentQuery = options.parentContentQuery || this._contentQuery;
    this._concurrency = options.concurrency || 2;
    this._showDuration = options.showDuration || 500;
    this._firstActionWaitTime = options.firstActionWaitTime || 100;
    this._controlCallback = options.controlCallback || function(query) {};

    this.$content = $(this._contentQuery);
    this.$control = $(this._controlQuery);
    this.$baseControl = $(this._baseControlQuery);
    this.$parentContent = $(this._parentContentQuery);

    this._currentQuery = null;
    this._vizs = [];
    this._loader = _createVizsLoader(this._vizs);
    this._mutex = new Mutex();

    this._setActions();
    this._runFirstAction();
  }

  _setActions() {
    if (!this.$baseControl.length) {
      return;
    }

    const that = this;

    this.$control.click(async event => {
      const query = $(event.currentTarget).attr('href');
      await that.show(that._findBaseQuery(event.currentTarget));
      return that._controlCallback(query);
    });

    this.$content.find('.viz-container').on('inview', (event, inView) => {
      if (!inView) {
        return false;
      }
      const tableauId = event.currentTarget.dataset.tableauId;
      _preferInViewVizs(that._loader, tableauId);
      that.findVizs(tableauId).forEach(viz => viz.updateSize());
    });
  }

  async _runFirstAction() {
    const defaultQuery = this._findBaseQuery($(this.$baseControl[0]));
    this.show(defaultQuery);

    if (!location.hash) {
      return;
    }
    const $control = this.$control.filter(`[href="${location.hash}"]`);
    if (!$control.length) {
      return;
    }

    const waitTime = this._firstActionWaitTime;
    await new Promise(resolve => setTimeout(resolve, waitTime));

    const query = $($control[0]).attr('href');
    await this.show(this._findBaseQuery($control[0]));
    this._controlCallback(query);
  }

  _findBaseQuery(control) {
    const $control = $(control);

    if ($control.is(this._baseControlQuery)) {
      return $control.attr('href');
    }

    const $children = $control.parent().find(this._baseControlQuery);
    if ($children.length) {
      return $($children[0]).attr('href');
    }

    for (let parent of $control.parents()) {
      const $parent = $(parent);
      if ($parent.has(this._baseControlQuery).length) {
        return $($parent.children(this._baseControlQuery)[0]).attr('href');
      }
    }

    return $(null);
  }

  findVizs(tableauId) {
    return _.filter(this._vizs, viz => viz.tableauId == tableauId);
  }

  async show(query) {
    if (query === this._currentQuery) {
      return;
    }
    return await this._load(query);
  }

  async _load(query) {
    _destructVizs(this._vizs, this._loader);

    $(document).scrollTop(0);

    const $target = $(query);
    const vizContainers = $target.find('.viz-container');

    const vizs = vizContainers
      .map((index, elem) => new TableauViz(elem, { index }))
      .get();
    const loader = _createVizsLoader(vizs);

    await this._mutex.synchronize(() => {
      this._currentQuery = query;
      this._vizs = vizs;
      this._loader = loader;

      for (let i = 0; i < this._concurrency; ++i) {
        loader.run();
      }
    });

    $.merge(this.$content, this.$parentContent).hide();
    await new Promise(resolve => {
      $.merge($target, $target.parents()).fadeIn({
        duration: this._showDuration,
        complete: resolve
      });
    });
  }
}
