import * as _ from 'lodash';

import Mutex from './mutex';

function displayErrorOnConsole(error) {
  console.error(error);
}

function defaultWaitReady() {
  return new Promise(resolve => setTimeout(resolve, 500));
}

export default class LazyLoader {
  constructor(items, load, options = {}) {
    this._itemQueue = _.clone(items);
    this._load = load;
    this._isReady = options.isReady || (() => true);
    this._waitReady = options.waitReady || defaultWaitReady;
    this._handleError = options.handleError || displayErrorOnConsole;

    this._current = null;
    this._mutex = new Mutex();
  }

  async clear() {
    this._itemQueue = [];
    if (this._current) {
      await this._current;
    }
  }

  async prefer(predicate) {
    const that = this;
    await this._mutex.synchronize(async () => {
      const prefered = _.filter(this._itemQueue, predicate);
      const notPrefered = _.filter(this._itemQueue, _.negate(predicate));
      that._itemQueue = prefered.concat(notPrefered);
    });
  }

  async preferAfter(predicate) {
    const that = this;
    await this._mutex.synchronize(async () => {
      const prefered = _.dropWhile(this._itemQueue, _.negate(predicate));
      const notPrefered = _.takeWhile(this._itemQueue, _.negate(predicate));
      that._itemQueue = prefered.concat(notPrefered);
    });
  }

  async preferBy(keys) {
    const that = this;
    await this._mutex.synchronize(async () => {
      that._itemQueue = _.sortBy(that._itemQueue, keys);
    });
  }

  async run() {
    while (this._itemQueue.length) {
      const that = this;

      let item = null;
      await this._mutex.synchronize(async () => {
        const index = _.chain(that._itemQueue)
          .map(that._isReady)
          .indexOf(true)
          .value();
        if (index < 0) {
          return;
        }
        item = that._itemQueue.splice(index, 1)[0];
      });
      if (!item) {
        await this._waitReady();
        continue;
      }

      const current = this._runUnit(item);
      this._mutex.synchronize(() => {
        that._current = current;
      });
      await current;
    }
  }

  async _runUnit(item) {
    try {
      await this._load(item);
    } catch (error) {
      this._handleError(error);
    }
  }
}
