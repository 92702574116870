export default class Mutex {
  constructor() {
    this._isBusy = false;
    this._queue = [];
  }

  async synchronize(task) {
    return await new Promise(resolve => {
      async function wrapper() {
        try {
          await task();
        } finally {
          resolve();
        }
      }

      this._queue.push(wrapper);
      if (!this._isBusy) {
        this._dequeue();
      }
    });
  }

  async _dequeue() {
    this._isBusy = true;
    const next = this._queue.shift();
    if (next) {
      await this._execute(next);
    } else {
      this._isBusy = false;
    }
  }

  async _execute(task) {
    try {
      await task();
    } finally {
      await this._dequeue();
    }
  }
}
