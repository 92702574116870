async function _handleErrorDefault(error, count) {
  console.warn(`Attempt ${count} failed: ${error}`);
}

export default function retry(attempt, options = {}) {
  // NOTE: Not use async/await so that the unit tests cover.
  const retries = options.retries || 3;
  const handleError = options.handleError || _handleErrorDefault;

  return new Promise((resolve, reject) => {
    for (let count = 1; count < retries; count++) {
      attempt()
        .then(resolve)
        .catch(error => handleError(error, count));
    }
    attempt()
      .then(resolve)
      .catch(reject);
  });
}
