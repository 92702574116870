import $ from 'jquery';

export class RadioButtonInput {
  constructor(query) {
    this._query = query;
    this._selector = $(this._query);
  }

  get selector() {
    return this._selector;
  }

  get value() {
    return this._selector.filter(':checked').val();
  }

  get text() {
    return this._selector
      .filter(':checked')
      .parent()
      .text();
  }

  set value(val) {
    this._selector.val([val]).change();
  }
}

export class SelectBoxInput {
  constructor(query) {
    this._query = query;
    this._selector = $(this._query);
  }

  get selector() {
    return this._selector;
  }

  get value() {
    return this._selector.val();
  }

  get text() {
    return this._selector.find(':selected').text();
  }

  set value(val) {
    this._selector.val(val).change();
  }
}

export class TextInput {
  constructor(query) {
    this._query = query;
    this._selector = $(this._query);
  }

  get selector() {
    return this._selector;
  }

  get value() {
    return this._selector.val();
  }

  get text() {
    return this.value;
  }

  set value(val) {
    this._selector.val(val).change();
  }
}
