/*!
 * To see the licenses of dependent libraries, see `./LICENSES.txt`.
 */
import * as _ from 'lodash';
import $ from 'jquery';

window.$ = $;

import 'cookieconsent';
import 'jquery-inview';
import 'select2';

import flatpickr from 'flatpickr';
import Foundation from '../vendor/foundation-setup';

import { InquiryModel } from '../model/inquiry';
import { LoginModel } from '../model/login';
import Mutex from '../model/mutex';
import { SingleGraphView, ArchiveGraphView } from '../view/graph';
import InquiryView from '../view/inquiry';
import LoginView from '../view/login';
import { stickySidebar, tracingSidebar } from '../view/sidebar';

function scrollTo(href, options = {}) {
  const offset = options.offset || 0;
  const speed = options.speed || 500;

  const scrollTop = $(href).offset().top - offset;
  $('html, body').animate({ scrollTop }, speed, 'swing');
  location.hash = href;
}

function createGraphView(headerOffset) {
  if (!$('.sidebar-content').length) {
    return new SingleGraphView('.graph-content');
  }

  return new ArchiveGraphView('.third-level-category', {
    parentContentQuery: '.second-level-category',
    controlCallback: href => scrollTo(href, { offset: headerOffset })
  });
}

function getLabelCountryFilter(filters, selector, type) {
  var country_name = 'None'
  if(filters){
    // console.log(filters)
    filters.forEach(element => {
      if(element.field.indexOf('国') != -1){
        country_name = element.values.join();
      }
    });
  }
  const top_name = selector.data('tableau-top-name');
  const second_name = selector.data('tableau-second-name');
  const third_name = selector.data('tableau-third-name');
  const detail_name = selector.data('tableau-detail-name');

  // console.log(country_name)
  var event_data =  {
    'country_name': country_name,
    'top_name': top_name,
    'second_name': second_name,
    'third_name': third_name,
    'detail_name': detail_name,
    'type': type
  };
  gtag('event', 'download_country', event_data);
}

function createGraphDownloadPDFAction(query, graphView) {
  $(query).click(async event => {
    const selector = $(event.currentTarget);
    const tableauId = selector.data('tableau-id');

    const viz = _.head(graphView.findVizs(tableauId));
    if (!viz) {
      console.error(`Failed to find the Tableau viz.: ${tableauId}`);
      return false;
    }

    const filters = await viz.fetchFilters();
    getLabelCountryFilter(filters, selector, 'pdf');

    await viz.showPdfDownloadDialog();
  });
}

function createGraphDownloadCSVAction(query, graphView, filterFlag) {
  $(query).click(async event => {
    const selector = $(event.currentTarget);
    const tableauId = selector.data('tableau-id');
    const csvTableauId = selector.data('csv-tableau-id');
    
    let filters = null;
    const filterViz = _.head(graphView.findVizs(tableauId));
    if (!filterViz) {
      console.error(`Failed to find the Tableau viz.: ${tableauId}`);
      return false;
    }
    try {
      filters = await filterViz.fetchFilters();
    } catch (err) {
      console.error(err);
      return false;
    }

    getLabelCountryFilter(filters, selector, 'csv');

    const viz = _.head(graphView.findVizs(csvTableauId));
    if (!viz) {
      console.error(`Failed to find the Tableau viz.: ${csvTableauId}`);
      return false;
    }
    await viz.showCSVDownloadDialog(filters, filterFlag);
  });
}

function createInquiryView(query) {
  const form = $(query).find('form');
  const model = new InquiryModel({
    sendUrl: form.attr('action'),
    sendMethod: form.attr('method')
  });
  return new InquiryView(query, model);
}

function createLoginView(query) {
  const form = $(query).find('form');
  const model = new LoginModel({
    sendUrl: form.attr('action'),
    sendMethod: form.attr('method')
  });
  return new LoginView(query, model);
}

function createCookieConsent(query) {
  const elem = $(query);
  if (!elem.length) {
    return;
  }

  const elements = {
    message: elem.find('.message').html()
  };
  const content = {
    dismiss: elem.find('.dismiss').text(),
    href: elem.find('.href').text()
  };
  window.cookieconsent.initialise({
    elements,
    content,
    palette: {
      popup: {
        background: 'rgba(255, 255, 255, 0.9)',
        text: '#000000'
      }
    },
    position: 'bottom-right',
    theme: 'classic',
    showLink: false
  });
}

function createHowToUse(query) {
  const elem = $(query);
  if (!elem.length) {
    return;
  }

  const keyName = 'visited';
  const keyValue = true;

  if (!localStorage.getItem(keyName)) {
      // localStorageにキーと値を追加
      localStorage.setItem(keyName, keyValue);
  } else {
      // 訪問済の場合は非表示とするクラスを追加
      elem.addClass('visited-view')
  }

  const mutex = new Mutex();
  elem.click(function() {
    mutex.synchronize(async () => {
      elem.fadeOut(2000);
    });
  });
}

function showErrorField(query) {
  const elem = $(query);
  if (!elem.length) {
    return;
  }

  const mutex = new Mutex();
  mutex.synchronize(async () => {
    if(location.search === "?error=true"){
      elem.removeClass('login-error-message');
    }
  });
}

function createTogglables(query, options = {}) {
  const activeClass = options.activeClass || 'is-active';
  const $triggers = $(query)
    .children()
    .children('a');
  const mutex = new Mutex();

  // Initialize.
  $triggers
    .filter(`:not(.${activeClass})`)
    .siblings('.toggled')
    .hide();

  $triggers.click(event => {
    mutex.synchronize(async () => {
      const $trigger = $(event.currentTarget);
      $trigger.toggleClass(activeClass);

      const isActive = $trigger.hasClass(activeClass);
      await new Promise(resolve =>
        $trigger
          .siblings('.toggled')
          .filter(isActive ? ':hidden' : ':visible')
          .slideToggle(400, resolve)
      );
    });

    event.stopImmediatePropagation();
    return false;
  });
}

function checkLink(query, options = {}) {
  var $triggers = $(query)
    .children()
    .children('a');

  const mutex = new Mutex();
  mutex.synchronize(async () => {
    const targetLink = location.hash || '#category--population';
    $triggers.removeClass('is-link');  
    $triggers.filter(`a[href$="${targetLink}"]`).addClass('is-link')
  });

  $triggers.click(event => {
    mutex.synchronize(async () => {
      $triggers.removeClass('is-link');
      const $trigger = $(event.currentTarget);
      $trigger.addClass('is-link')
    });
  });
}

$(document).ready(() => {
  $(document).foundation();
  $('.select2').select2({ width: '100%' });
  flatpickr('.date-picker', { dateFormat: 'Y-m-d' });
  flatpickr('.time-picker', {
    dateFormat: 'H:i',
    noCalendar: true,
    enableTime: true,
    time_24hr: true
  });

  const headerOffset = $('header').outerHeight() + 30;

  createTogglables('.togglable.menu');
  createTogglables('.togglable2.menu');
  checkLink('.link-arrow.menu');
  stickySidebar('.sticky-sidebar');
  tracingSidebar('.sidebar-content .item>a', '.graph-post-container', {
    offset: headerOffset
  });

  // createCookieConsent('#cookie-consent');
  createHowToUse('.how-to-use');
  showErrorField('.login-error-message');
  createInquiryView('#inquiry-form');

  const scrollSpeed = 500;
  const graphView = createGraphView(headerOffset);

  createGraphDownloadPDFAction('.tableau-download-pdf', graphView);

  createGraphDownloadCSVAction('.tableau-download-csv', graphView, true);
  
  createGraphDownloadCSVAction('.tableau-download-csvall', graphView, false);
  
});
