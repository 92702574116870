import $ from 'jquery';

class StickySidebar {
  constructor(sidebarSel, anchorSel) {
    this._sidebar = $(sidebarSel);
    this._anchor = $(anchorSel);

    $(window)
      .resize(() => {
        this._updateSize();
      })
      .resize();

    $(window)
      .scroll(() => {
        this._stick();
      })
      .scroll();
  }

  _updateSize() {
    this._sidebar.outerHeight($(window).height());
    this._sidebar.outerWidth(this._sidebar.parent().innerWidth());
  }

  _stick() {
    const anchorBottom = this._anchor.offset().top + this._anchor.outerHeight();
    const rest = anchorBottom - $(window).height();

    if ($(window).scrollTop() > rest) {
      const top =
        this._anchor.outerHeight() -
        this._sidebar.outerHeight() +
        this._anchor.offset().top;
      this._sidebar.css({ position: 'absolute', top });
    } else {
      const top = this._anchor.offset().top;
      this._sidebar.css({ position: 'fixed', top });
    }
  }
}

export function stickySidebar(query) {
  const selector = $(query);
  return $(query).map((idx, elem) => {
    const anchor = elem.dataset.anchor || 'body';
    return new StickySidebar(elem, anchor);
  });
}

class TracingSidebar {
  constructor(sidebarQuery, contentQuery, options = {}) {
    this.$sidebar = $(sidebarQuery);
    this.$content = $(contentQuery);

    this._offset = options.offset || 0;
    this._tolerance = options.tolerance || 100;

    this._setActions();
  }

  _setActions() {
    const that = this;

    $(window)
      .scroll(() => that.trace())
      .scroll();
  }

  trace() {
    const topBorder = $(window).scrollTop() + this._offset;

    const $followers = this.$content.filter(':visible').filter((idx, elem) => {
      const $elem = $(elem);
      const top = $elem.offset().top;
      const bottom = top + $elem.height();

      if (top > topBorder + this._tolerance) {
        return false;
      }
      if (bottom < topBorder) {
        return false;
      }

      return true;
    });
    if (!$followers.length) {
      return;
    }
    const id = $($followers[0]).attr('id');

    this.$sidebar.each((idx, elem) => {
      const $elem = $(elem);
      $elem.toggleClass('is-active', $elem.is(`[href="#${id}"]`));
    });
  }
}

export function tracingSidebar(sidebarQuery, contentQuery, options = {}) {
  return new TracingSidebar(sidebarQuery, contentQuery, options);
}
