import $ from 'jquery';
import axios from 'axios';

import { TextInput } from './form';

export default class InquiryView {
  constructor(query, model) {
    this._query = query;
    this._model = model;

    this._selector = $(query);
    this._forms = {
      input: this._selector.find('.input-form'),
      confirm: this._selector.find('.confirm-form'),
      complete: this._selector.find('.complete-form')
    };
    this._inputs = {
      userName: new TextInput(this._selector.find('[name=user-name]')),
      userCompany: new TextInput(this._selector.find('[name=user-company]')),
      userDivision: new TextInput(this._selector.find('[name=user-division]')),
      userEmail: new TextInput(this._selector.find('[name=user-email]')),
      inquiryBody: new TextInput(this._selector.find('[name=inquiry-body]')),
      aboutSiteAgreed: this._selector.find('[name=about-site-agreed]')
    };
    this._confirms = {
      userName: this._forms.confirm.find('.user-name'),
      userCompany: this._forms.confirm.find('.user-company'),
      userDivision: this._forms.confirm.find('.user-division'),
      userEmail: this._forms.confirm.find('.user-email'),
      inquiryBody: this._forms.confirm.find('.inquiry-body')
    };
    this._errors = {
      input: this._forms.input.find('.message'),
      send: this._forms.confirm.find('.message')
    };
    this._toggles = {
      toInput: this._selector.find('.to-input'),
      toConfirm: this._selector.find('.to-confirm'),
      send: this._selector.find('.send')
    };

    this.setActions();
  }

  get aboutSiteAgreed() {
    return (
      this._inputs.aboutSiteAgreed.filter(':checked').val() === 'agreed'
    );
  }

  setActions() {
    const that = this;
    this._toggles.toConfirm.click(() => that.moveToConfirmForm());
    this._toggles.toInput.click(() => that.moveToInputForm());
    this._toggles.send.click(async () => {
      const $target = that._toggles.send;
      $target.css('pointer-events', 'none').addClass('disabled');
      try {
        await that.send();
      } finally {
        $target.css('pointer-events', 'inherit').removeClass('disabled');
      }
    });
  }

  scrollToTop() {
    $(window).scrollTop(0);
  }

  moveToInputForm() {
    this._errors.input.hide();
    this._forms.confirm.hide();
    this._forms.input.show();
    this.scrollToTop();
    return true;
  }

  moveToConfirmForm() {
    this._model.inquiry.name = this._inputs.userName.value;
    this._model.inquiry.company = this._inputs.userCompany.value;
    this._model.inquiry.division = this._inputs.userDivision.value;
    this._model.inquiry.email = this._inputs.userEmail.value;
    this._model.inquiry.body = this._inputs.inquiryBody.value;

    this._errors.input.hide();
    this._errors.input.find('li').hide();

    const validationErrors = this._model.validate();
    if (validationErrors.length) {
      validationErrors.forEach(error => {
        this._errors.input.find(`.${error.source}.${error.cause}`).show();
      });
      this._errors.input.show();
      return;
    }

    this._confirms.userName.text(this._inputs.userName.text);
    this._confirms.userCompany.text(this._inputs.userCompany.text);
    this._confirms.userDivision.text(this._inputs.userDivision.text);
    this._confirms.userEmail.text(this._inputs.userEmail.text);
    this._confirms.inquiryBody.html(
      replaceAsHTML(this._inputs.inquiryBody.text)
    );
    this._forms.input.hide();
    this._forms.confirm.show();
    this._errors.send.hide();
    this.scrollToTop();
    return true;
  }

  moveToCompleteForm() {
    this._errors.input.hide();
    this._forms.confirm.hide();
    this._forms.complete.show();
    return true;
  }

  async send() {
    this._errors.send.hide();
    this._errors.send.find('li').hide();

    if (!this.aboutSiteAgreed) {
      this._errors.send.find('.about-site-agreement.not-agreed').show();
      this._errors.send.show();
      return false;
    }

    try {
      await this._model.send();
    } catch (error) {
      console.error(`${error.name}: ${error.message}`);
      this._errors.send.find('.send.failed').show();
      this._errors.send.show();
      return false;
    }

    this.moveToCompleteForm();

    return true;
  }
}

function replaceAsHTML(str) {
  return str.replace(/\n/g, '</br>');
}
