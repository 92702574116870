import $ from 'jquery';
import queryString from 'query-string';

import retry from '../model/retry';

const tableau = window.tableau;

const CSV_URL_INVALID_FIELDS = new Set(['メジャー ネーム', 'Measure Names']);

export default class TableauViz {
  constructor(element, options = {}) {
    this._element = element;
    this._tableauId = this._element.dataset.tableauId;
    this._baseUrl = `https://public.tableau.com/views/${this._tableauId}`;
    this._csvTableauId = this._element.dataset.csvTableauId;
    this._csvBaseUrl = `https://public.tableau.com/views/${this._csvTableauId}`;
    this._index = options.index || 9999;
    this._maxNumFilterValue = options.maxNumFilterValue || 50;

    this._viz = null;
    this._filters = [];
  }

  get isVisible() {
    return $(this._element).is(':visible');
  }

  get tableauId() {
    return this._tableauId;
  }

  get index() {
    return this._index;
  }

  async load(options = {}) {
    const maxWaitTime = options.maxWaitTime || 10000;

    await new Promise((resolve, reject) => {
      this._viz = new tableau.Viz(this._element, this._baseUrl, {
        hideTabs: true,
        hideToolbar: true,
        onFirstInteractive: resolve
      });
      setTimeout(
        () => reject(new Error(`Viz loading timed out: ${this._tableauId}`)),
        maxWaitTime
      );
    });
  }

  async refresh() {
    if (!this._viz) {
      console.warn('Viz is not created.');
      return;
    }
    this._viz.refreshDataAsync();
  }

  dispose() {
    if (!this._viz) {
      return;
    }
    this._viz.dispose();
  }

  changeFilter(filters, filterFlag) {
    if(!filters){
      return;
    }
    if(filterFlag){
      this.appliedFilters(filters);
    }else{
      this.clearFilters(filters);
    }
  }

  findWorksheet() {
    if (!this._viz) {
      console.warn('Viz is not created.');
      return null;
    }
    const workbook = this._viz.getWorkbook();
    const sheet = workbook.getActiveSheet();
    switch (sheet.getSheetType()) {
      case tableau.SheetType.WORKSHEET:
        return sheet;
      case tableau.SheetType.DASHBOARD:
        const worksheets = sheet.getWorksheets();
        if (!worksheets.length) {
          throw new Error('No worksheet found.');
        }

        const graphWorksheets = _.filter(worksheets, worksheet =>
          worksheet.getName().startsWith('WS_')
        );
        if (graphWorksheets.length) {
          return graphWorksheets[0];
        }
        return worksheets[0];
      default:
        throw new Error(`Invalid sheet type found: ${sheet.getSheetType()}`);
    }
  }

  async fetchFilters() {
    const sheet = this.findWorksheet();
    if (!sheet) {
      return null;
    }

    const filters = await retry(async () => await sheet.getFiltersAsync(), {
      async handleError(error, count) {
        console.warn(`Attempt ${count} failed: ${error}`);
        await new Promise(resolve => setTimeout(resolve, 2000)); // Sleep.
      }
    });
    return filters
      .filter(
        filter => filter.getFilterType() === tableau.FilterType.CATEGORICAL
      )
      .map(filter => ({
        field: filter.getFieldName(),
        values: filter.getAppliedValues().map(v => v.formattedValue)
      }))
      .filter(filter => {
        if (filter.values.length > this._maxNumFilterValue) {
          console.warn(
            `Filter "${
              filter.field
            }" is ignored as all values are set since more than ${
              this._maxNumFilterValue
            } values are set.`
          );
          return false;
        }
        return true;
      });
  }

  async appliedFilters(filters) {
    const sheet = this.findWorksheet();
    if (!sheet) {
      return null;
    }
    filters.forEach(element => {
      sheet.applyFilterAsync(element.field, element.values, tableau.FilterUpdateType.REPLACE)
    });
  }

  async clearFilters(filters) {
    const sheet = this.findWorksheet();
    if (!sheet) {
      return null;
    }
    filters.forEach(element => {
      sheet.clearFilterAsync(element.field)
    });
  }

  async updateSize() {
    if (!this._viz) {
      return;
    }
    const width = $(this._element).innerWidth();
    const height = $(this._element).innerHeight();
    return this._viz.setFrameSize(width, height);
  }

  async showPdfDownloadDialog() {
    if (!this._viz) {
      console.warn('Viz is not created.');
      return null;
    }

    await Promise.all([
      $(window)
        .scrollTop($(this._element).offset().top)
        .promise(),
      this.refresh()
    ]);
    return this._viz.showExportPDFDialog();
  }

  async showCSVDownloadDialog(filters, filterFlag) {
    if (!this._viz) {
      console.warn('Viz is not created.');
      return null;
    }

    await Promise.all([
      this.changeFilter(filters, filterFlag),
      this.refresh()
    ]);
    this._viz.showExportDataDialog();
  }
}
