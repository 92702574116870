import axios from 'axios';
import validator from 'validator';

export class Inquiry {
  constructor() {
    this._name = null;
    this._company = null;
    this._division = null;
    this._email = null;
    this._body = null;
  }

  set name(value) {
    this._name = value;
  }

  set company(value) {
    this._company = value;
  }

  set division(value) {
    this._division = value;
  }

  set email(value) {
    this._email = value;
  }

  set body(value) {
    this._body = value;
  }

  get name() {
    return this._name;
  }

  get company() {
    return this._company;
  }

  get division() {
    return this._division;
  }

  get email() {
    return this._email;
  }

  get body() {
    return this._body;
  }

  validate() {
    const errors = [];
    if (!this._name) {
      errors.push({ source: 'user-name', cause: 'is-empty' });
    }
    if (!this._company) {
      errors.push({ source: 'user-company', cause: 'is-empty' });
    }
    if (!this._email) {
      errors.push({ source: 'user-email', cause: 'is-empty' });
    }
    if (this._email && !validator.isEmail(this._email)) {
      errors.push({ source: 'user-email', cause: 'invalid-format' });
    }
    if (!this._body) {
      errors.push({ source: 'inquiry-body', cause: 'is-empty' });
    }
    return errors;
  }
}

export class InquiryModel {
  constructor(options = {}) {
    this._client = options.client || axios;
    this._sendMethod = options.sendMethod || 'post';
    this._sendUrl = options.sendUrl || '/access/send-inquiry.php';

    this._inquiry = options.inquiry || new Inquiry();
  }

  get inquiry() {
    return this._inquiry;
  }

  validate() {
    return this._inquiry.validate();
  }

  /**
   * @return Promise instance.
   */
  send() {
    const inquiry = this._inquiry;
    const data = {
      name: inquiry.name,
      company: inquiry.company,
      division: inquiry.division,
      email: inquiry.email,
      body: inquiry.body
    };

    const config = {
      method: this._sendMethod,
      url: this._sendUrl,
      data
    };
    return this._client.request(config);
  }
}
